<template>
  <div class="es-modal">
    <div class="row">
      <div class="col-md-11 col-xs-12">
        <h2 class="text-size-h2 text-bold mb-m">
          {{ currentPrivilege ? currentPrivilege.title : "Ошибка" }}
        </h2>
      </div>
    </div>
    <BaseModalContent
      :dynamic="true"
      name="es-modal"
      @close="$emit('close')">
      <div v-if="!currentPrivilege">У вас нет этой привилегии</div>
      <div v-else>
        <div v-if="!selectedProfile">
          <div
            v-if="expiredDate"
            class="mb-m">
            Можно воспользоваться до: {{ expiredDate }}
          </div>
          <p
            v-if="currentPrivilege.description"
            class="mb-m">
            {{ currentPrivilege.description }}
          </p>
          <div v-if="allowedProfiles && allowedProfiles.length">
            <div class="text-bold text-size-h4 mb-s">
              Эта привилегия может быть учтена в следующих
              {{ isJunior ? "Сферах" : "Профилях" }}:
            </div>
            <div
              v-for="item in allowedProfiles"
              :key="item.profile_id"
              class="p-card">
              <div class="p-card__data">
                <div class="p-card__title text-size-m text-bold">
                  {{ item.title }}
                </div>

                <div v-if="item.selected">
                  <div class="text-size-s">
                    <span class="color-meta"
                      ><BaseIcon glyph="done" />&nbsp;{{
                        isJunior ? "Сфера выбрана" : "Профиль выбран"
                      }}</span
                    >&nbsp;
                    <a
                      v-if="!item.disabled"
                      href="#"
                      class="link link--pseudo"
                      @click.prevent="
                        handleRefuseProfile(item.profile_id, item.title)
                      "
                      >Отменить выбор</a
                    >
                  </div>
                </div>

                <div v-else>
                  <div class="text-size-s">
                    <span class="color-meta">{{
                      isJunior ? "Сфера не выбрана" : "Профиль не выбран"
                    }}</span
                    >&nbsp;
                    <a
                      v-if="!isLimitReached && !item.disabled"
                      href="#"
                      class="link link--pseudo"
                      @click.prevent="handleSelectProfile(item)"
                      >Выбрать {{ isJunior ? "сферу" : "профиль" }}</a
                    >
                  </div>
                </div>
                <div
                  v-if="item.disabled && item.disabledMesage"
                  class="color-error text-size-s">
                  {{ item.disabledMesage }}
                </div>
              </div>
              <div class="p-card__control">
                <div v-if="item.selected && !isExpired && !item.disabled">
                  <BaseButton
                    theme="success"
                    class="es-modal__btn"
                    @click.prevent="selectedProfile = item"
                    >{{ privilegeActionLabel }}</BaseButton
                  >
                </div>
              </div>
            </div>

            <BaseButton
              class="mt-l es-modal__btn"
              theme="primary"
              @click="$emit('close')"
              >Выберу позже</BaseButton
            >
          </div>
          <div v-else>
            <p>Нет подходящих профилей (сфер) для этой привилегии.</p>
          </div>
        </div>
        <div v-else-if="success">
          <div class="text-size-h4 mb-l">
            Привилегия успешно добавлена.
            <span v-if="currentPrivilege && currentPrivilege.score">
              Баллы за&nbsp;профиль будут пересчитаны в&nbsp;течение
              12&nbsp;часов.</span
            >
          </div>
          <BaseButton
            class="es-modal__btn"
            @click="$emit('close')"
            >Закрыть</BaseButton
          >
        </div>
        <div v-else>
          <div class="text-size-h4 mt-m mb-m">
            <p class="mb-m">
              <span class="text-bold">Внимание!</span> Отменить выбор будет
              невозможно.
            </p>

            Вы уверены что хотите {{ privilegeActionLabel }} для
            {{ isJunior ? "сферы" : "профиля" }} &laquo;{{
              selectedProfile.title
            }}&raquo;?
          </div>

          <BaseButton
            class="es-modal__btn"
            :disabled="pending"
            @click.prevent="handleSubmit"
            >Да, уверен</BaseButton
          >&emsp;
          <BaseButton
            theme="primary-border"
            class="es-modal__btn"
            @click.prevent="selectedProfile = null"
            >Нет, выберу {{ isJunior ? "другую" : "другой" }}</BaseButton
          >
        </div>
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DATE_FROMAT_WITH_UTC, FINAL_STAGE } from "@/constants";
import { numCases } from "@/utils";
import { request } from "@/services/api";
import dayjs from "@/plugins/dayjs";
import { getJuniorFinalEvent } from "@/utils/participant";
export default {
  name: "ExtraScoresModal",
  props: {
    privilege: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedProfile: null,
      success: false,
      pending: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedList: "participant/selectedList",
      isJunior: "participant/isJunior",
      profileLimit: "participant/profileLimit",
      currentEducation: "participant/currentEducation",
    }),
    events() {
      return this.$store.state.profile.events;
    },
    selectedIds() {
      const { selectedList } = this;
      if (!selectedList?.length) return [];
      return selectedList.map((n) => n.profile_id);
    },
    currentPrivilege() {
      const { privilege } = this;
      const list = this.$store.state.participant.privileges;
      if (!list || !list.length) return;
      return list.find((n) => {
        return n.id === privilege;
      });
    },
    isExpired() {
      const { currentPrivilege } = this;
      if (!currentPrivilege) return;
      return dayjs().isAfter(currentPrivilege.expired_at);
    },
    expiredDate() {
      const date = this.currentPrivilege?.expired_at;
      if (!date) return;
      return dayjs(date).format(DATE_FROMAT_WITH_UTC);
    },
    profiles() {
      return this.$store.state.profile.profiles;
    },
    allowedProfiles() {
      const { currentPrivilege, selectedIds, profiles } = this;
      if (!currentPrivilege || !currentPrivilege?.profiles?.length) return;
      const result = currentPrivilege.profiles
        .reduce((acc, id) => {
          if (profiles[id]) {
            const p = {
              title: profiles[id].title,
              profile_id: id,
              selected: selectedIds.includes(id),
            };
            // Для джунов получим финальный ивент
            if (this.isJunior) {
              const events = profiles[id].steps
                ?.filter(
                  (step) => step.stage === FINAL_STAGE && step.talent_event_id
                )
                .map((step) => {
                  return this.events[step.talent_event_id];
                });
              p.finalEvent = getJuniorFinalEvent(events, this.currentEducation);
              if (!p.finalEvent) {
                p.disabled = true;
                p.disabledMesage = "Не проводится в вашем регионе";
              }
            }
            acc.push(p);
          }
          return acc;
        }, [])
        .sort((a, b) => b.selected - a.selected);

      return result;
    },
    privilegeActionLabel() {
      const { currentPrivilege } = this;
      if (currentPrivilege.score) {
        return `добавить +${currentPrivilege.score} ${numCases(
          ["балл", "балла", "баллов"],
          currentPrivilege.score
        )}`;
      }
      return "применить привилегию";
    },
    isLimitReached() {
      const { profileLimit, selectedIds, profiles } = this;
      const profCount = selectedIds.reduce((acc, id) => {
        if (profiles[id] && !profiles[id].is_special_project) {
          acc += 1;
        }
        return acc;
      }, 0);

      return profCount >= profileLimit;
    },
  },
  async created() {
    if (this.isJunior) {
      try {
        await this.$store.dispatch("profile/getAllFinalEvents");
      } catch (error) {
        this.$emit("close");
        this.showErrorModal({
          content: "Не удалось получить список городов, где проводятся финалы.",
          message: error.message,
        });
      }
    }
  },
  methods: {
    async handleSelectProfile(profile) {
      const { selectedIds } = this;
      // если профиля нет в выбранных
      if (!selectedIds.includes(profile.profile_id)) {
        const payload = { profile_id: profile.profile_id };
        if (this.isJunior && !profile.finalEvent) {
          this.showErrorModal({
            message:
              "Вы не можете принять участие в этой сфере, так как финальный этап не проводится в вашем регионе",
            title: `Регистрация на сферу <br/> &laquo;${profile.title}&raquo;`,
          });
          return;
        } else if (this.isJunior && profile.finalEvent) {
          payload.final_event = profile.finalEvent.id;
        }
        try {
          await this.$store.dispatch("participant/selectProfile", payload);
          window.dataLayer?.push({
            event: "ntoProfileEvent",
            eventCategory: "nto_profile",
            eventAction: "profile_add_new",
            userProfile: profile.title,
          });
        } catch (error) {
          this.showErrorModal({
            message: error.message,
            status: error.status,
          });
        }
      }
    },
    async handleRefuseProfile(profile_id, title) {
      const { selectedIds } = this;
      // если профиля нет в выбранных
      if (selectedIds.includes(profile_id)) {
        try {
          await this.$store.dispatch("participant/rejectProfile", profile_id);
          window.dataLayer?.push({
            event: "ntoProfileEvent",
            eventCategory: "nto_profile",
            eventAction: "profile_remove",
            userProfile: title,
          });
        } catch (error) {
          this.showErrorModal({
            message: error.message,
            status: error.status,
          });
        }
      }
    },
    async handleSubmit() {
      const { selectedProfile, currentPrivilege } = this;
      if (!selectedProfile || !currentPrivilege || this.pending) return;
      const payload = {
        privilege_id: currentPrivilege.privilege_id,
        profile_id: selectedProfile?.profile_id,
      };
      this.pending = true;
      try {
        await request({
          url: "/participant/use_privilege",
          method: "POST",
          data: payload,
        });
        this.success = true;
        this.$store.commit("participant/PATCH_PRIVILEGE", {
          id: currentPrivilege.id,
          profile_id: payload.profile_id,
        });

        /**
         * если это сгруппированная привилегия,
         * то применим и вторую
         */
        if (currentPrivilege._group_with_privilege_id) {
          try {
            await request({
              url: "/participant/use_privilege",
              method: "POST",
              data: {
                ...payload,
                privilege_id: currentPrivilege._group_with_privilege_id,
              },
            });
          } catch (err) {
            //
          }
        }
      } catch (error) {
        this.showErrorModal({
          message: error.message,
          status: error.status,
        });
        this.success = false;
      }
      this.pending = false;
    },
  },
};
</script>

<style lang="less" scoped>
.es-modal {
  &__btn {
    min-width: 160px;
  }
}
.p-card {
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid fade(@link-color, 50%);
  padding: 20px;
  align-items: center;
  justify-content: space-between;

  & + & {
    border-top: 0;
  }

  .media-max-sm({
    display: block;
  });

  &__control {
    .media-max-sm({
      margin-top: 20px;
    });
  }
}
</style>
